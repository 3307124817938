import { Typography, makeStyles } from "@material-ui/core";
import React from 'react'
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import FolderOutlinedIcon from '@material-ui/icons/FolderOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import ImportContactsOutlinedIcon from '@material-ui/icons/ImportContactsOutlined';
import ReceiptOutlinedIcon from '@material-ui/icons/ReceiptOutlined';
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import ReplayOutlinedIcon from '@material-ui/icons/ReplayOutlined';
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu, Sidebar } from "semantic-ui-react";
import { TypeRole } from '../../enums/index';
import iconLogo from "../../img/octusIcon.png";
import "../../mediasQueries/mediaQueries.css";
import { setModal } from "../../redux/modal";
import ModalIntegration from "../Integrations/ModalIntegration";
import AuthenticatedHeader from "../common/AuthenticatedHeader/AuthenticatedHeader";
import ManagmentSettings from "../managment/ManagmentSettings";
import Settings from "../configurations/Settings";
import SideBarItem from "./SideBarItem";
import "./Sidebar.css";

const useStyles = makeStyles(()=>({
	sideBarIconStyles: {
		fontSize: "2em", 
		marginRight: 5
	},
	sideBarTextItemStyles: {
		fontSize: "1em", 
		color: "#363534"
	},
	containerSideBar: {
		marginTop: 10,
		display: "flex",
		flexDirection: "column",
		padding: 2,
		overflowY: "visible"
	},
	btnCloseMenu:{
		position: "absolute",
		right: 0,
		top: 0,
		outline: "none",
		borderBottomLeftRadius: "6px",
		borderBottomRightRadius: "6px",
		border: "none",
		color: "white",
		backgroundColor: "#ef4f5b",
		width: 25,
		height: 25,
		cursor: "pointer",
		zIndex: "100"
	}
}));
const buttonSelected = (textUrl) => {
    let url = window.location.href;
    if (url.includes(textUrl)) {
        return true;
    } else {
        return false;
    }
};
export default function SidebarCommon({ dataToast, setDataToast, Component }) {
	const classStyle = useStyles();
	const dispatch = useDispatch();
	const hasManagment = useSelector(({managment}) => managment.hasManagment);

	//TODO: para las configuraciones o lo que se tiene permitido y que no utilizar 1 sola variable de estado, no todo por separado
	const [patient, setPatient] = useState(false);
	const [returns, setReturns] = useState(false);
	const [document, setDocument] = useState(false);
	const [periods, setPeriods] = useState(false);
	const [holiday, setHoliday] = useState(false);
	const [presentation, setPresentation] = useState(false);
	const [configurations, setConfigurations] = useState(false);
	const [configUser, setConfigUser] = useState(false);
	const [configAccount, setConfigAccount] = useState(false);
	const [typeRolUser, setTypeRolUser] = useState();
	const [liquidation, setLiquidation] = useState(false);
	const [cruds, setCruds] = useState(false);
	const [instructiveList, setInstructiveList] = useState(false);
	const [amountHistorial, setAmountHistorial] = useState(false);
	const [viewSideBar, setViewSideBar] = useState(false);	
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	function HandleOnClick(){
        dispatch(setModal(true));
    }

	useEffect(() => {
		setPatient(JSON.parse(localStorage.getItem('patient')));
		setPresentation(JSON.parse(localStorage.getItem('presentation')));
		setReturns(JSON.parse(localStorage.getItem('returns')));
		setPeriods(JSON.parse(localStorage.getItem('periods')));
		setDocument(JSON.parse(localStorage.getItem('document')));
		setHoliday(JSON.parse(localStorage.getItem('holiday')));
		setConfigurations(JSON.parse(localStorage.getItem('configurations')));
		setConfigUser(JSON.parse(localStorage.getItem('configUser')));
		setConfigAccount(JSON.parse(localStorage.getItem('configAccount')));
		setAmountHistorial(JSON.parse(localStorage.getItem('amountHistorial')));
		setTypeRolUser(JSON.parse(localStorage.getItem('TypeRole')));
		setCruds(JSON.parse(localStorage.getItem('cruds')));
		setLiquidation(JSON.parse(localStorage.getItem('liquidation')));
		setInstructiveList(JSON.parse(localStorage.getItem('instructive')));
	});

	useEffect(() => {
		// Función para manejar el cambio en la resolución de pantalla
		function handleScreenResolutionChange() {
		  setScreenWidth(window.innerWidth);
		}
	
		// Agregar un evento de cambio de tamaño de ventana
		window.addEventListener("resize", handleScreenResolutionChange);
	
		// Llama a la función inicialmente para obtener la resolución actual de la pantalla
		handleScreenResolutionChange();
	
		// Asegúrate de quitar el evento cuando el componente se desmonte
		return () => {
		  window.removeEventListener("resize", handleScreenResolutionChange);
		};
	},[])

	return (
		<>
			
		<Sidebar
			className={`background-sidebar ${viewSideBar && screenWidth <= 912 ? "" : "sideBar-menu"}`}
			as={Menu}
			on
			animation='overlay'
			direction='left'
			icon='labeled'
			inverted
			onHide={()=>setViewSideBar(false)}
			vertical
			visible={screenWidth <= 912 ? viewSideBar : true}
		>
			
			{screenWidth <= 912 && viewSideBar && <button className={classStyle.btnCloseMenu} onClick={()=>setViewSideBar(prev => !prev)}>X</button>}
			<SideBarItem urlToRedirect={`${presentation ? "/Inicio" : "#"}`} titleTooltip="Indicadores">
				<>
					<img style={{ width: 45, marginRight: 10, mixBlendMode: "darken"}} src={iconLogo} alt='logo' />
					<Typography className={classStyle.sideBarTextItemStyles}>Octus</Typography>
				</>
			</SideBarItem>

				<nav className={classStyle.containerSideBar}>
					{patient && (
						<SideBarItem urlToRedirect="/pacientes/lista" titleTooltip="Pacientes">
							<>
								<AssignmentOutlinedIcon className={classStyle.sideBarIconStyles}/>
								<Typography className={classStyle.sideBarTextItemStyles}>Pacientes</Typography>
							</>
						</SideBarItem>
					)}
					{periods && (
						<SideBarItem urlToRedirect="/periodos/lista" titleTooltip="Períodos">
							<>
								<TodayOutlinedIcon className={classStyle.sideBarIconStyles}/>
								<Typography className={classStyle.sideBarTextItemStyles}>Períodos</Typography>
							</>
						</SideBarItem>
					)}
					{returns && (
						<SideBarItem urlToRedirect="/devoluciones/lista" titleTooltip="Devoluciones">
							<>
								<ReplayOutlinedIcon className={classStyle.sideBarIconStyles}/>
								<Typography className={classStyle.sideBarTextItemStyles}>Devoluciones</Typography>
							</>
						</SideBarItem>	
					)}
					{liquidation && (
						<SideBarItem urlToRedirect="/liquidaciones/lista" titleTooltip="Liquidaciones">
							<>
								<ReceiptOutlinedIcon className={classStyle.sideBarIconStyles}/>
								<Typography className={classStyle.sideBarTextItemStyles}>Liquidaciones</Typography>
							</>
						</SideBarItem>
					)}
					{instructiveList && (
						<SideBarItem urlToRedirect="/instructivos/lista" titleTooltip="Instructivos">
							<>
								<ImportContactsOutlinedIcon className={classStyle.sideBarIconStyles}/>
								<Typography className={classStyle.sideBarTextItemStyles}>Instructivos</Typography>		
							</>
						</SideBarItem>
					)}
					{amountHistorial && (
						<SideBarItem urlToRedirect="/montos/lista" titleTooltip="Montos">
							<>
								<AttachMoneyOutlinedIcon className={classStyle.sideBarIconStyles}/>
								<Typography className={classStyle.sideBarTextItemStyles}>Montos</Typography>		
							</>
						</SideBarItem>
					)}
					{document && (
						<SideBarItem urlToRedirect="/documentos/lista" titleTooltip="Documentos">
							<>
								<RecentActorsOutlinedIcon className={classStyle.sideBarIconStyles}/>
								<Typography className={classStyle.sideBarTextItemStyles}>Documentos</Typography>
							</>
						</SideBarItem>
					)}
					{holiday && (
						<SideBarItem urlToRedirect="/feriados/lista" titleTooltip="Feriados">
							<>
								<DateRangeOutlinedIcon className={classStyle.sideBarIconStyles}/>
								<Typography className={classStyle.sideBarTextItemStyles}>Feriados</Typography>
							</>
						</SideBarItem>
					)}
					{typeRolUser == TypeRole.Colegio && (
						<SideBarItem urlToRedirect="/documentos-por-periodo/listazip" titleTooltip="Documentos por Período">
							<>
								<AttachFileOutlinedIcon className={classStyle.sideBarIconStyles}/>
								<Typography className={classStyle.sideBarTextItemStyles}>Doc. por Período</Typography>
							</>
						</SideBarItem>
					)}
					{typeRolUser == TypeRole.Admin && (
						<SideBarItem urlToRedirect="#" titleTooltip="Integración" dispatchAction={HandleOnClick}>
							<>
								<FolderOutlinedIcon className={classStyle.sideBarIconStyles} />
								<Typography className={classStyle.sideBarTextItemStyles}>Integración</Typography>
							</>
						</SideBarItem>
					)}
					
					{ ((hasManagment && typeRolUser === TypeRole.Admin) || typeRolUser === TypeRole.SuperAdmin) && <ManagmentSettings/>}

					{(configurations == true || typeRolUser === TypeRole.Colegio) && 
							<Settings
								configUser={configUser}
								cruds={cruds}
							/>
					}

					{typeRolUser == TypeRole.Professional && (
						<SideBarItem
							urlToRedirect="https://drive.google.com/drive/folders/1jclfpiK8UNVJlshz32DDjNK-xAmfWf5Y?usp=sharing"
							titleTooltip="Ayuda"
							target="_blank">
							<>
								<LiveHelpOutlinedIcon className={classStyle.sideBarIconStyles} />
								<Typography className={classStyle.sideBarTextItemStyles}>Ayuda</Typography>
							</>
						</SideBarItem>
					)}

					<ModalIntegration setDataToast={setDataToast}/>
				</nav>
				
			</Sidebar>
			<div className='margin-right-component-sidebar'>
				<AuthenticatedHeader setViewSideBar={setViewSideBar} stateOfSideBar={viewSideBar} setDataToast={setDataToast}/>
				<div className="styleContent-app">
					<Component dataToast={dataToast} setDataToast={setDataToast} />
				</div>
			</div>
		</>
	);
}
