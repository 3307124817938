
const _TITLE_OF_VERSION = "VERSIONES";

export const informationOfVersions = [
    [
        {
            titleOfSection: _TITLE_OF_VERSION,
            accordionContent: [
                {
                    dateOfChange: "2023-05-07",
                    titleOfChange: `(05/07/2023) - Versión 1.1.0`,
                    paragraphs: [
                        {title: "Profesionales"},

                        {text: "Se valida que aparezca el número de autorización y número de siniestro dependiendo de la configuración de la obra social."},
                        {text: "Si la obra social tiene configurado un alias se visualiza el mismo."},

                        {title: "Administradores"},
                        
                        {text: "Nuevo filtro en el listado de obras sociales para buscar por dadas de baja o por nombre."},
                        {text: "Nuevo filtro en el listado de prácticas asociadas a la obra social para buscar por dadas de baja o por nombre."},
                        {text: "Nueva funcionalidad de 'Reactivar' para obras sociales o prácticas dadas de baja."},
                        {text: "Posibilidad de editar un profesional sin importar si tiene devoluciones pendientes."},
                        {text: "Posibilidad de agregar descripción a la liquidación."},
                        {text: "Posibilidad de crear alias a obras sociales para que el profesional vea el nombre como la regional lo desea"},
                        {text: "Posibilidad de configurar por obra social el número de autorización y número de siniestro."},
                        {text: "Posibilidad de crear un alias o editarlo en las obras sociales asignadas."},
                        {text: "Posibilidad de habilitar o no el campo 'Numero de siniestro' para la obra social asignada seleccionada."},
                        {text: "En la lista de obras sociales se agrega el alias."},
                        {text: "Posibilidad de agregar un texto abreviado a los nombres de las prácticas para mejorar la impresión de reportes"},
                        {text: "Posibilidad de devolver un paciente de un período en curso."},
                    ]
                },
                {
                    dateOfChange: "2023-07-21",
                    titleOfChange: `(21/07/2023) - Versión 1.1.1`,
                    paragraphs: [
                        {title: "Profesionales"},

                        {text: "Visualización del campo coseguro (donde podrá asignar un monto opcionalmente) dependiendo de la configuración asignada por el administrador en la obra social."},
                        {text: "Posibilidad de editar la matrícula del prescriptor al momento de editar un paciente."},

                        {title: "Administradores"},
                        
                        {text: "El administrador puede configurar las obras sociales las cuales tienen habilitado un coseguro."},
                        {text: "Posibilidad de configurar alias y abreviatura para los reportes a las prácticas. "},
                        {text: "Posibilidad de visualizar las imágenes de los pacientes en la edición de periodo."},
                        {text: "Se agregó el logo de Octus para todos los reportes PDF que se imprimieran en la aplicación."},
                    ]
                },
                {
                    dateOfChange: "2023-09-12",
                    titleOfChange: `(12/09/2023) - Versión 1.1.2`,
                    paragraphs: [
                        {title: "Profesionales"},

                        {text: "Al seleccionar una obra social se listarán las prácticas asociadas a la misma y si poseen, un monto que acompaña a cada práctica."},
                        {text: "Todas las fechas de las sesiones al dar de alta un Paciente, se listan en orden de menor a mayor."},
                        {text: "Se habilitó la acción “Presentar” para presentar pacientes desde la sección Devoluciones."},
                        {text: "La sección 'Mi Cuenta' donde se visualizan los datos de la cuenta, cambió a 'Perfil'."},
                        {text: "Se agregó una nueva sección 'Lista de Instructivos' donde se visualizan todas las obras sociales las cuales poseen un instructivo adjunto."},
                        {text: "En la sección 'Dar de alta un paciente' al seleccionar una obra social se mostrará un icono 'Instructivo' en caso de esa obra social tenga adjunto un instructivo."},

                        {title: "Auditor"},

                        {text: "En caso de no visualizar el archivo que contiene todas las imágenes del periodo cerrado, podrá utilizar la acción 'Procesar Archivos' en la sección 'Documentos por Periodos' para generarlo."},

                        {title: "Administradores"},

                        {text: "En la sección 'Listado de obras sociales' se habilitó la acción 'Instructivo' para agregar un instructivo en cada obra social, donde podrá editar y/o agregar."},
                        {text: "En la sección 'Periodos' dentro de 'Exportar' se habilitó la acción 'Reporte' para imprimir el resumen mensual de prestaciones."},
                        {text: "Se agregó el logo de Octus en cada archivo exportado desde el sistema."},
                        {text: "En la sección 'Exportar Excel' podrá configurar si desea mostrar los montos asociados a las prácticas en los archivos exportados desde el sistema."},
                        {text: "En la sección 'Obras Sociales' al seleccionar una práctica asociada a la obra social, podrá: Asignar, Editar, Eliminar o ver un historial de montos establecidos para la práctica."},
                        {text: "Se habilitó la acción 'Reactivar' para los profesionales que fueron dados de baja en la sección 'Profesionales'"},
                        {text: "En la sección Configuraciones - Exportar Excel, se agregó la opción de 'Mostrar Monto' con el fin de que el administrador configure las columnas a mostrar en los excels exportados desde el sistema."},
                        {text: "Se habilitó la acción 'Blanquear Contraseña' para modificar la contraseña del profesional y asignarle el número de matrícula como nueva contraseña."}
                    ]
                },
                {
                    dateOfChange: "2024-02-05",
                    titleOfChange: `(05/02/2024) - Versión 1.1.3`,
                    paragraphs: [
                        {title: "Profesionales"},

                        {text: "Integración de Coseguro con Swiss Medical y OSPE. Al momento de informar una práctica con integración la misma obtendrá el coseguro informado por la Obra Social."},
                        {text: "Identificación de prácticas informadas con integración y manuales para permitir realizar acciones de edición sobre las practicas manuales."},
                        {text: "Mejora visual para versión mobile."},
                        {text: "Mejora en mensaje de error al momento de falla en integraciones."},

                        {title: "Administradores"},

                        {text: "Mejora visual en la edición de instructivo."},
                        {text: "Nuevos filtros de Obra Social y Estado en listado de Pacientes informados por Período."},
                        {text: "Nueva forma de visualización de Estados en listado de Pacientes informados por Período."},
                        {text: "Funcionalidad para editar Pacientes (solamente los que no fueron informados por integración) desde el listado de Pacientes informados por Período."}
                    ]
                },
                {
                dateOfChange: "2024-02-21",
                titleOfChange: `(21/02/2024) - Versión 1.1.4`,
                paragraphs: [
                    {title: "Auditor"},

                    {text: "Se habilitó la sección 'Configuraciones' en la barra lateral de menús, donde el Auditor podrá configurar las obras sociales que corresponden a la institución, y configurar los montos de las prácticas asociadas a las mismas."},

                    {title: "Profesionales"},

                    {text: "Se habilitó la sección 'Montos' en la barra lateral de menús, donde el Profesional podrá visualizar los montos vigentes de una Obra Social seleccionada."},
                    {text: "Fix en edición de Paciente. Se agregan nuevas validaciones teniendo en cuenta la configuración de la Obra Social y/o actualización del coseguro al momento de la edición."},
                    {text: "Campo 'Coseguro' en la sección 'Nuevo Paciente' / 'Editar Paciente', para aquellas obras sociales sin integración activada o configuración para autorizar por sesión individual, se deberá de ingresar el monto total del Coseguro. En caso de que la Obra Social tenga configurado autorizar por sesión individual el monto del Coseguro será por cada sesión."},

                    {title: "Administradores"},

                    {text: "Nueva columna, 'Monto Sesión' a los reportes Excel. Ahora se podrá diferenciar el Monto Total, Monto Sesión y Monto Coseguro por Paciente."},
                    {text: "Se agregaron datos en la creación o edición de un Profesional."},
                    {text: "Mejora en el orden de las grillas de todas las secciones según la fecha en la que fueron dados de alta los registros, manteniendo siempre los registros nuevos en la parte superior."},
                    {text: "En la sección Montos, eliminamos el filtro de 'Montos Vigentes'. Ahora el administrador podrá visualizar los montos vigentes por defecto y en caso de querer ver montos antiguos tendrá habilitada la funcionalidad de 'Historial'."},
                    {text: "Se redujeron los decimales (de 4 a 2) para los valores de montos en los reportes exportados desde el sistema."},
                ]
                },
                {
                    dateOfChange: "2024-04-09",
                    titleOfChange: `(09/04/2024) - Versión 1.1.5`,
                    paragraphs: [
                        {title: "Profesionales"},

                        {text: "Agregamos un icono de campana que señala la cantidad de novedades que hayan dado de alta los administradores y/o auditores. Se podrá visualizar la totalidad de la información haciendo click en cada una de ellas."},

                        {title: "Administradores"},
                        
                        {text: "Agregamos la sección 'NOVEDADES' para perfiles administrador y auditor donde se podrán cargar novedades con la posibilidad de agregar videos o imágenes."},
                        {text: "En la grilla de Montos asociados a las prácticas de una obra social no será posible agregar un monto con fecha igual a una ya dada de alta. Tampoco se podrán editar, eliminar o asignar nuevos montos con fecha menor a la actual."},

                        {title: "Auditor"},
                        
                        {text: "Agregamos la sección 'NOVEDADES' para perfiles administrador y auditor donde se podrán cargar novedades con la posibilidad de agregar videos o imágenes."},
                    ]
                },
                {
                    dateOfChange: "2024-04-15",
                    titleOfChange: `(15/04/2024) - Versión 1.1.6`,
                    paragraphs: [
                        {title: "Profesionales"},

                        {text: "Se agrega la funcionalidad de agregar Sesiones desde la edición del Paciente, para las Obras Sociales que se pueden agregar de a una Sesión."},
                        
                        {title: "Administradores"},

                        {text: "Se ordenaron los registros que están impresos en el reporte master, se ordenan por apellido del profesional o por número de matrícula."},
                    ]
                },
                {
                    dateOfChange: "2024-04-29",
                    titleOfChange: `(29/04/2024) - Versión 1.1.7`,
                    paragraphs: [
                        {title: "Profesionales"},

                        {text: "Nuevo filtro de  Estado en Pacientes para identificar si está presentado o aún no."},
                        
                        {title: "Auditor"},

                        {text: "Se agrega el estándar 'Apellido, Nombre' a las carpetas de generación de imágenes."},
                    ]
                },
                {
                    dateOfChange: "2024-05-15",
                    titleOfChange: `(15/05/2024) - Versión 1.1.8`,
                    paragraphs: [
                        {title: "Profesionales"},

                        {text: "Estandarización de Apellido y Nombre en las grillas y reportes para profesionales."},
                        {text: "Nueva funcionalidad para configurar y validar por cada Obra Social la cantidad de caracteres en el Nro. Afiliado."},
                        {text: "Nueva validación que requiere al profesional tener un CUIT configurado a la hora de presentar un paciente con Obra Social que tenga configurada integración."},
                        {text: "Nueva funcionalidad para validar el afiliado de una Obra Social que tiene integración. Se puede hacer a través del botón 'Validar Afiliado' en la sección 'Paciente'."},

                        {title: "Auditor"},
                        
                        {text: "Nueva funcionalidad que permite hacer una actualización masiva de Montos de las Prácticas."},
                    ]
                },
                {
                    dateOfChange: "2024-06-14",
                    titleOfChange: `(14/06/2024) - Versión 1.1.9`,
                    paragraphs: [
                        {title: "Auditor"},
                        {text: "Nueva funcionalidad que permite eliminar masivamente los Montos de las Prácticas."},

                        {title: "Profesionales"},
                        {text: "Nueva funcionalidad de Sancor Salud que permite crear una Preautorización."},
                        {text: "Fix en el cambio de contraseña para Usuarios."},
                        {text: "Nueva integración con Obra Social Avalian."},

                    ]
                },
                {
                    dateOfChange: "2024-06-27",
                    titleOfChange: `(27/06/2024) - Versión 1.1.10`,
                    paragraphs: [
                        {title: "Colegio"},
                        {text: "Nueva funcionalidad que permite asignar Prácticas a las Obras Sociales."},
                    ]
                },
                {
                    dateOfChange: "2024-07-19",
                    titleOfChange: `(19/07/2024) - Versión 1.1.11`,
                    paragraphs: [
                        {title: "Profesionales"},
                        {text: "Se agrega la configuración de token para la integración con Obra Sancor Medicina Privada."},
                        {text: "Se agrega la configuración de token para la integración con Obra Social Avalian."},
                        {text: "Mejora en asignación de Fecha de eliminado de Sesiones y Fechas de Sesiones."},
                    ]
                },
                {
                    dateOfChange: "2024-09-04",
                    titleOfChange: `(04/09/2024) - Versión 1.2`,
                    paragraphs: [
                        {title: "FACTURACIÓN"},
                        {title: "Profesionales"},
                        {text: "Nueva integración con Obra Social Prevención Salud"},

                        {title: "GESTIÓN"},
                        {text: "Nuevos módulos Descuentos, Cuotas, Acreditaciones, Facturas, Recibos y Configuración de Regional."},
                        {text: "Se modificó el funcionamiento de liquidaciones. Si tiene gestión activa permite realizar liquidaciones sobre facturas y descuentos de profesionales, en caso de no tener gestión activa sigue funcionando a través de un texto."},
                        {text: "Nuevos Reportes de liquidación: Obras Sociales, Obra Social por Periodo, Saldos, Liquidación, Profesionales."},
                        {text: "Se agregó Retención de ganancias y se podrá descargar el reporte que se encuentra dentro de cualquier liquidación según el profesional seleccionado."},
                        {text: "Al editar o crear un profesional ahora se pueden seleccionar si el mismo cuenta con un Seguro."},
                        {text: "Mejoras dentro de la sección de familiares para la experiencia de usuario."},
                    ]
                },
                {
                    dateOfChange: "2024-09-24",
                    titleOfChange: `(24/09/2024) - Versión 1.2.1`,
                    paragraphs: [
                        {title: "Profesionales"},
                        {text: `Nuevo menú "Ayuda" para acceder a documentación`},
                        {text: `Extensión de tiempo en notificaciones para que sean fácilmente legibles.`},
                        {text: `Se agregó validación para que no se pueda agregar manualmente un número de autorización eliminado previamente en una Obra Social con integración.`},

                        {title: "Administradores"},
                        {text: "Posibilidad de agregar imágenes a un paciente."},
                    ]
                },
            ],
        },
        
    ],
]